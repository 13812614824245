<template>
<v-container class="grey lighten-4 px-4" fluid>
  <v-card elevation="0" class="pa-2 mb-4">
    <v-container fluid>
      <v-row>
        <span class="text-h5 font-weight-bold ma-1">OKR 仪表盘</span>
        <v-spacer></v-spacer>
        <div class="mt-5 mx-3">
          <Calendar @pick="cycleHandle" :isWhole="true" />
        </div>
        <v-menu v-model="menu" left offset-y :close-on-content-click="false">
          <template v-slot:activator="{on, attrs}">
            <v-btn color="#2879ff" class="mt-5 mx-3 pa-1" width="140" v-bind="attrs" v-on="on" outlined>
              <div style="width:100px;overflow:hidden;">{{ currentDepartment | department }}</div>
              <v-spacer></v-spacer>
              <v-icon small>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <DepartmentGroupPicker @pick="pickDepartment" />
        </v-menu>
      </v-row>
      <!-- tabs -->
      <v-row>
        <v-tabs color="#1565C0" slider-size="4" v-model="tab">
          <v-tabs-slider width="100"></v-tabs-slider>
          <v-tab class="text-body-1 font-weight-black">
            OKR设定
          </v-tab>
          <v-tab class="text-body-1 font-weight-black">
            OKR追踪
          </v-tab>
          <v-tab class="text-body-1 font-weight-black">
            OKR结果分析
          </v-tab>
        </v-tabs>
      </v-row>
    </v-container>
  </v-card>
  <!-- tab items -->
  <v-tabs-items tag="div" class="grey lighten-4" v-model="tab">
    <!-- Okr setting tab -->
    <v-tab-item transition="scroll-y-transition">
      <v-container fluid>
        <!-- rate cards -->
        <v-row>
          <RateCards v-if="dashSetting" :data="dashSetting.settingRate" />
        </v-row>
        <!-- employee chart, kr chart -->
        <v-row>
          <RateCircleChart @selectLegend1="selectLegend1" @selectLegend2="selectLegend2" v-if="dashSetting" :data2="dashSetting.settingObjectKr" :data1="dashSetting.settingUserObject" />
        </v-row>
        <!-- okr alignment, kr progress -->
        <v-row>
          <RateBar @notParent="notParent" @notDissolve="notDissolve" @legend1="settingObjectParents" @legend2="SettingkrDissolve" v-if="dashSetting" :notParentUsers="dashNotParentUsers" :notDissolveUsers="dashNotDissolveUsers" :data1="dashSetting.SettingObjectParent" :data2="dashSetting.SettingkrDissolve" />
        </v-row>
      </v-container>
    </v-tab-item>
    <!-- Okr track tab -->
    <v-tab-item transition="scroll-y-transition">
      <v-container fluid>
        <!-- Track cards -->
        <v-row>
          <TrackCards @obNotProgress="obNotProgress" @krNotProgress="krNotProgress" @exeNotProgress="exeNotProgress" v-if="dashTrack" :dashTrack="dashTrack" />
        </v-row>
        <v-row>
          <OkrTrendChart v-if="trackProgress" :data="trackProgress" />
        </v-row>
      </v-container>
    </v-tab-item>
    <!-- Okr result analysis tab -->
    <v-tab-item transition="scroll-y-transition">
      <v-container fluid>
        <v-row>
          <OverallAverage v-if="dashAnalyze" :average="dashAnalyze.OKRAverageProgress" :departAverage="dashAnalyze.OKRDepartmentProgress" />
        </v-row>
        <v-row>
          <OkrScore @selectLengend="analyzeScoreObjects" v-if="dashAnalyze" :scoreCounts="dashAnalyze.OKRScoreCounts" :departScore="dashAnalyze.OKRDepartmentScore" />
        </v-row>
      </v-container>
    </v-tab-item>
  </v-tabs-items>
  <SettingUsersDlg :title="dialogTitle" :users="dashSettingUsers" ref="settingUsersDlg" />
  <SettingObjectsDlg :title="dialogTitle" :objects="dashSettingObjects" ref="settingObjectsDlg"/>
  <StObjectParentsDlg :title="dialogTitle" :objects="dashSettingObjectParents" ref="stObjectParentsDlg"/>
  <KrDissolvesDlg :title="dialogTitle" :krs="dashKrDissolves" ref="krDissolvesDlg" />
  <NotUsersDlg :title="dialogTitle" :users="dashNotParentUsers" ref="dashNotParentsDlg" />
  <NotUsersDlg :title="dialogTitle" :users="dashNotDissolveUsers" ref="dashNotDissoveDlg" />
  <ObNotProgressDlg :title="dialogTitle" :objects="dashTrackObNotProgress" ref="obNotProgressDlg" />
  <KrDissolvesDlg :title="dialogTitle" :krs="dashTrackKrNotProgress" ref="krNotProgressDlg" />
  <ExeNotProgressDlg :title="dialogTitle" :tasks="dashTrackExeNotProgress" ref="exeNotProgressDlg" />
  <SettingObjectsDlg :title="dialogTitle" :objects="dashAnalyzeScoreObjects" ref="settingScoreObjectsDlg"/>
</v-container>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';
export default {
  components: {
    Calendar: () => import('@/components/common/Calendar.vue'),
    RateCards: () => import('@/components/okrdashboard/RateCards.vue'),
    RateCircleChart: () => import('@/components/okrdashboard/RateCircleChart.vue'),
    RateBar: () => import('@/components/okrdashboard/RateBar.vue'),
    TrackCards: () => import('@/components/okrdashboard/TrackCards.vue'),
    OkrTrendChart: () => import('@/components/okrdashboard/OkrTrendChart.vue'),
    OverallAverage: () => import('@/components/okrdashboard/OverallAverage.vue'),
    OkrScore: () => import('@/components/okrdashboard/OkrScore.vue'),
    DepartmentGroupPicker: () => import('@/components/common/DepartmentGroupPicker.vue'),
    SettingUsersDlg: () => import('@/components/okrdashboard/SettingUsersDlg.vue'),
    SettingObjectsDlg: () => import('@/components/okrdashboard/SettingObjectsDlg.vue'),
    StObjectParentsDlg: () => import('@/components/okrdashboard/StObjectParentsDlg.vue'),
    KrDissolvesDlg: () => import('@/components/okrdashboard/KrDissolvesDlg.vue'),
    NotUsersDlg: () => import('@/components/okrdashboard/NotUsersDlg.vue'),
    ObNotProgressDlg: () => import('@/components/okrdashboard/ObNotProgressDlg.vue'),
    ExeNotProgressDlg: () => import('@/components/okrdashboard/ExeNotProgressDlg.vue')
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("dashboard", 
                  ["dashSetting", 
                  "dashTrack", 
                  "dashAnalyze",                   
                  "trackProgress", 
                  "dashSettingUsers", 
                  "dashSettingObjects", 
                  "dashSettingObjectParents",
                  "dashKrDissolves",
                  "dashNotParentUsers",
                  "dashNotDissolveUsers",
                  "dashTrackObNotProgress",
                  "dashTrackKrNotProgress",
                  "dashTrackExeNotProgress"]),
  },
  data() {
    return {
      tab: null,
      fillRate: 71,
      alignmentRate: 18,
      executionRate: 7,
      cycle: null,
      dpSerialId: null,
      menu: false,
      currentDepartment: null,
      dialogTitle: '',
      dashAnalyzeScoreObjects: [],
    }
  },
  methods: {
    ...mapActions("dashboard", 
                  ["getSetting", 
                  "getTrack", 
                  "getAnalyze", 
                  "getTrackProgress",
                  "getDashSettingUsers", 
                  "getDashSettingObjects", 
                  "getDashSettingObjectParents",
                  "getDashKrDissolves",
                  "getNotParentUsers",
                  "getNotDissolveUsers",
                  "getTrackObNotProgress",
                  "getTrackKrNotProgress",
                  "getTrackExeNotProgress",
                  "getAnalyzeScoreObjects"]),
    ...mapActions('department', ['getAllDepartments']),
    ...mapActions('user', ['getUsers']),
    getCurrentCycleNumber() {
      // Get current date, quater and filter okr objects
      // 获取当前日期、季度和过滤 okr 对象
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth() + 1;
      let start, end, cycle;
      if (month >= 1 && month <= 3) {
        month = '/1';
        start = year + '/01/01';
        end = year + '/03/31';
        cycle = year + month;
      }
      if (month >= 4 && month <= 6) {
        month = '/2';
        start = year + '/04/01';
        end = year + '/06/30';
        cycle = year + month;
      }
      if (month >= 7 && month <= 9) {
        month = '/3';
        start = year + '/07/01';
        end = year + '/09/30';
        cycle = year + month;
      }
      if (month >= 10 && month <= 12) {
        month = '/4';
        start = year + '/10/01';
        end = year + '/12/31';
        cycle = year + month;
      }
      return cycle
    },
    cycleHandle(e) {
      if (e.cycle.includes('/1000')) {
        this.cycle = '%'
        this.getSetting({
          req_cycle: '%',
          req_dp_serial: this.dpSerialId
        })
        this.getTrack({
          req_cycle: '%',
          req_dp_serial: this.dpSerialId
        })
        this.getAnalyze({
          req_cycle: '%',
          req_dp_serial: this.dpSerialId
        })
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        this.getTrackProgress({
          req_cycle: this.cycle,
          req_dp_serial: this.dpSerialId,
          req_date: today
        })
      } else {
        this.cycle = e.cycle
        this.getSetting({
          req_cycle: this.cycle,
          req_dp_serial: this.dpSerialId
        })
        this.getTrack({
          req_cycle: this.cycle,
          req_dp_serial: this.dpSerialId
        })
        this.getAnalyze({
          req_cycle: this.cycle,
          req_dp_serial: this.dpSerialId
        })
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        this.getTrackProgress({
          req_cycle: this.cycle,
          req_dp_serial: this.dpSerialId,
          req_date: today
        })
      }
      this.getNotParentUsers({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId
      });
      this.getNotDissolveUsers({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId
      });
      this.getTrackObNotProgress({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId
      });
      this.getTrackKrNotProgress({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId
      });
      this.getTrackExeNotProgress({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId
      });
    },
    pickDepartment(e) {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + '-' + mm + '-' + dd;
      this.currentDepartment = e.id
      this.dpSerialId = `${e.dp_serial_id}.${e.id}`;
      this.getSetting({
        req_cycle: this.cycle,
        req_dp_serial: `${e.dp_serial_id}.${e.id}`
      });
      this.getTrack({
        req_cycle: this.cycle,
        req_dp_serial: `${e.dp_serial_id}.${e.id}`
      })
      this.getAnalyze({
        req_cycle: this.cycle,
        req_dp_serial: `${e.dp_serial_id}.${e.id}`
      })
      this.getTrackProgress({
        req_cycle: this.cycle,
        req_dp_serial: `${e.dp_serial_id}.${e.id}`,
        req_date: today
      })
      this.getNotParentUsers({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId
      });
      this.getNotDissolveUsers({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId
      });
      this.getTrackObNotProgress({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId
      });
      this.getTrackKrNotProgress({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId
      });
      this.getTrackExeNotProgress({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId
      });
    },
    selectLegend1(e) {
      let option = 0;
      if (e == 2) {
        option = 5;
        this.dialogTitle = '设置5个以上目标的人员';
      } else {
        option = e
        this.dialogTitle = option == 0 ? '未设置目标的成员' : '设置1-5个目标的人员';
      }
      this.getDashSettingUsers({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId,
        req_option: option
      });
      this.$refs.settingUsersDlg.open();
    },
    selectLegend2(e) {
      let option = e == 0 ? 0 : e * 2 - 1;
      switch (option) {
        case 0:
          this.dialogTitle = '未添加关键成果的目标';
          break;
        case 1:
          this.dialogTitle = '设置1-2个关键成果的目标';
          break;
        case 3:
          this.dialogTitle = '设置3-5个关键成果的目标';
          break;
        default:
          this.dialogTitle = '设置5个以上关键成果的目标';
      }
      this.getDashSettingObjects({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId,
        req_option: option
      });
      this.$refs.settingObjectsDlg.open();
    },
    settingObjectParents(e) {
      this.dialogTitle = e ? '未添加对齐的目标' : '已添加对齐的目标';
      this.getDashSettingObjectParents({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId,
        req_option: Number(!e)
      });
      this.$refs.stObjectParentsDlg.open();
    },
    SettingkrDissolve(e) {
      this.dialogTitle = e ? '未添加E-执行的目标' : '已添加E-执行的目标';
      this.getDashKrDissolves({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId,
        req_option: Number(!e)
      })
      this.$refs.krDissolvesDlg.open();
    },
    notParent() {
      // Open the dashboard not parent dialog
      this.$refs.dashNotParentsDlg.open();
    },
    notDissolve() {
      //Open the dialog box for not dissolve
      this.$refs.dashNotDissoveDlg.open();
    },
    obNotProgress() {
      this.dialogTitle = '一周内未更新的目标';
      this.$refs.obNotProgressDlg.open();
    },
    krNotProgress() {
      this.dialogTitle = '一周内未更新的关键成果';
      this.$refs.krNotProgressDlg.open();
    },
    exeNotProgress() {
      this.dialogTitle = '一周内未更新的E-执行';
      this.$refs.exeNotProgressDlg.open();
    },
    analyzeScoreObjects(e) {
      this.getAnalyzeScoreObjects({
        req_cycle: this.cycle,
        req_dp_serial: this.dpSerialId,
        req_option: e + 1
      }).then(res => {
        this.dashAnalyzeScoreObjects = res;
        if(e == 0) {
          this.dialogTitle = '未进行评分的目标';
        } else if (e == 1) {
          this.dialogTitle = '评分为0-0.3分的目标';
        } else if (e == 2) {
          this.dialogTitle = '评分为0.4-0.7分的目标';
        } else if (e == 3) {
          this.dialogTitle = '评分为0.8-1分的目标';
        }
        this.$refs.settingScoreObjectsDlg.open()
      })
    }
  },
  mounted() {
    // Get all departments
    this.getAllDepartments()
    // Get current cycle number
    this.cycle = this.getCurrentCycleNumber()
    // Set the department id
    this.dpSerialId = `.${this.authUser.user_company_id}`
    // Set the current department
    this.currentDepartment = this.authUser.user_company_id
    // Get setting
    this.getSetting({
      req_cycle: this.cycle,
      req_dp_serial: this.dpSerialId
    })
    // Get track
    this.getTrack({
      req_cycle: this.cycle,
      req_dp_serial: this.dpSerialId
    })
    // Get analyze
    this.getAnalyze({
      req_cycle: this.cycle,
      req_dp_serial: this.dpSerialId
    })
    // Get today's date
    let today = new Date();
    // Set the date
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    // Set the date
    today = yyyy + '-' + mm + '-' + dd;
    // Get track progress
    this.getTrackProgress({
      req_cycle: this.cycle,
      req_dp_serial: this.dpSerialId,
      req_date: today
    });
    // Get users
    this.getUsers();
    // Get not parent users
    this.getNotParentUsers({
      req_cycle: this.cycle,
      req_dp_serial: this.dpSerialId
    });
    // Get not dissolve users
    this.getNotDissolveUsers({
      req_cycle: this.cycle,
      req_dp_serial: this.dpSerialId
    });
    // Get track ob not progress
    this.getTrackObNotProgress({
      req_cycle: this.cycle,
      req_dp_serial: this.dpSerialId
    });
    // Get track kr not progress
    this.getTrackKrNotProgress({
      req_cycle: this.cycle,
      req_dp_serial: this.dpSerialId
    });
    // Get track exe not progress
    this.getTrackExeNotProgress({
      req_cycle: this.cycle,
      req_dp_serial: this.dpSerialId
    });
  }
}
</script>

<style lang="css">

</style>
